$(window).on('load', function(){
  var windowHeight = $(window).height(); //ウインドウの高さを取得
 
  lineAnimation();
  textMove();

  $(window).on('scroll', function(){
      lineAnimation();
      textMove();
  });

  //スクロールに合わせてクラス「js-active」を与える
  function lineAnimation(){
      var scroll = $(window).scrollTop(); //スクロールの位置を取得 
      $('[data-animation]').each(function(){
          var position = $(this).offset().top;
          if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
              $(this).addClass('js-active'); //クラス「js-active」を与える
          }
      });
  }

  //スクロールに合わせてテキスト表示
  function textMove(){
      var scroll = $(window).scrollTop(); //スクロールの位置を取得 
      $('[data-textMove]').each(function(){
          var position = $(this).offset().top;
          if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
              $(this).addClass('js-move'); //クラス「js-move」を与える
          }
      });
  }
});